// foundation
@import 'foundation';
@include foundation-everything;

// font-awesome
@import "font-awesome";

// _sass/
@import 'mixins';

// animate.css
@import "_properties";
@import '_fading-entrances/_fadeIn';

// slick-carousel
@import 'slick';
@import 'slick-theme';

// Google fonts
@import url("https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,600");

@keyframes toColor {
  0%    { -webkit-filter: grayscale(100%); filter: grayscale(100%); }
  25%   { -webkit-filter: grayscale(75%); filter: grayscale(75%); }
  50%   { -webkit-filter: grayscale(50%); filter: grayscale(50%); }
  50%   { -webkit-filter: grayscale(25%); filter: grayscale(25%); }
  100%  { -webkit-filter: grayscale(0%); filter: grayscale(0%); }
}

$source-font: 'Source Sans Pro', sans-serif;
$mobileSize: 'max-width: 650px';
$desktopSize: 'min-width: 649px';

.only-small {
  display: none;
}
@media only screen and ($mobileSize) {
  .only-small {
    display: block !important;
  }
  .only-desktop {
    display: none !important;
  }
}

$headerHeightDesktop: 50px;
$headerHeightMobile: 70px;
$footerHeightDesktop: 50px;
$footerHeightMobile: 50px;

header {
  @media only screen and ($desktopSize) {
    height: $headerHeightDesktop;
  }
  @media only screen and ($mobileSize) {
    height: $headerHeightMobile;
  }
  top: 0;
}

footer {
  @media only screen and ($desktopSize) {
    height: $footerHeightDesktop;
  }
  @media only screen and ($mobileSize) {
    height: $footerHeightMobile;
  }
  bottom: 0;
}

.block-size {
  @media only screen and ($mobileSize) {
    width: calc(100% / 2);
  }
  @media only screen and ($desktopSize) {
    width: calc(100% / 3)
  }
  @media only screen and (min-width: 1050px) {
    width: calc(100% / 4)
  }
  @media only screen and (min-width: 1400px) {
    width: calc(100% / 5)
  }
}

$masonryGutter: 40px;
// Not used anymore
.block-size-gutter {
  @media only screen and ($mobileSize) {
    width: calc(((100% + #{$masonryGutter}) / 1) - #{$masonryGutter});
  }
  @media only screen and ($desktopSize) {
    width: calc(((100% + #{$masonryGutter}) / 3) - #{$masonryGutter});
  }
  //@media only screen and (min-width: 1050px) {
    //width: calc(((100% + #{$masonryGutter}) / 4) - #{$masonryGutter});
  //}
  //@media only screen and (min-width: 1400px) {
    //width: calc(((100% + #{$masonryGutter}) / 5) - #{$masonryGutter});
  //}
}
.gutter-sizer {
  width: $masonryGutter;
}

header, footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  background-color: white;

  // center
  margin: 0 auto;
  left: 0;
  right: 0;

  @media only screen and ($desktopSize) {
    // - 100px for the .white-container padding
    width: calc(100% - 100px);
  }
  @media only screen and ($mobileSize) {
    // - 40px for the .white-container padding
    //width: calc(100% - 40px);
    // need white space on the left/right for full screen animation
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
  }
  z-index: 1;
}

.title-container {
  height: 100vh;

  display: flex;
  align-items: center;
  justify-context: center;

  .title {
    flex: 1;

    text-align: center;
    font-family: $source-font;
    font-size: 24px;
    font-weight: 200;

    .main-title {
      font-weight: 600;
    }
  }
}

.white-container {
  background-color: white;
  //overflow-x: hidden; // Because of the negative margin on project-list
  //overflow-y: auto;
  @media only screen and ($desktopSize) {
    // Ratio 2/3
    //width: calc(1.5 * (100vh - #{($headerHeightDesktop + $footerHeightDesktop)})
    //+ #{($headerHeightDesktop + $footerHeightDesktop)});
    width: 100%;
  }

  @media only screen and ($desktopSize) {
    padding-left: 50px;
    padding-right: 50px;
  }
  @media only screen and ($mobileSize) {
    padding-left: 20px;
    padding-right: 20px;
  }
  margin: 0 auto;
  position: relative;
}

.slideshow-container {
  @media only screen and ($desktopSize) {
    height: calc(100vh - 100px);
  }
  @media only screen and ($mobileSize) {
    height: calc(100vh - 120px);
  }
}

.main-container.slideshow-content {
  height: 100vh;
}

.main-container {
  @media only screen and ($desktopSize) {
    padding-top: $headerHeightDesktop;
    padding-bottom: $footerHeightDesktop;
  }
  @media only screen and ($mobileSize) {
    padding-top: $headerHeightMobile;
    padding-bottom: $footerHeightMobile;
  }
}
.all-background {
  position: absolute;
  width: 100%;
  height: 100%;
}

.slideshow-container {
  position: absolute;
  z-index: 0;
  @media only screen and ($desktopSize) {
    width: calc(100% - 100px);
    top: 50px;
  }
  @media only screen and ($mobileSize) {
    width: calc(100% - 40px);
    top: 70px;
  }
}

/* the slides */
.slick-slide {
    margin: 0 5px;
}
/* the parent */
.slick-list {
    margin: 0 -5px;
}

/*
 * Image size/positioning logic
 *
 * Desktop
 *        - width: auto (according to height)
 *        - height: 100%
 * => Image can be cut on the right/left
 * SPECIAL RULE:
 *        First image on the project page is cover
 *        All the div area is filled with image
 *
 * Mobile
 *        - width: 100%
 *        - height: auto (according to width)
 * => Image can be cut on the top/bottom
 *
 */
.background-container {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: -1;
  text-align: center;
  overflow: hidden;

  // Hide the background on IE
  background-size: 0 0;

  opacity: 0;
  &.show {
    opacity: 1;

    animation: toColor 2s;
    z-index: 0;
  }


  img {
    // Make it not selectable
    -moz-user-select: none;
    -webkit-user-select: none;
    user-select: none;

    @media only screen and ($desktopSize) {
      height: 100%;
      object-fit: cover; // If image's width doesn't fit container, crop it
    }
    @media only screen and ($mobileSize) {
      width: 100%;
      // Vertical center image overflowing on top/bottom
      position: absolute;
      top:-100%; left:0; right: 0; bottom:-100%;
      margin: auto;
      object-fit: cover; // If image's height doesn't fit container, crop it
    }

    // object-fit: cover compatibility for IE
    &.compat-object-fit {
      img {
        opacity: 0;
      }
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
    }
  }

  @include transition(opacity, 1s);
}

// Cover-mode (first one of project is cover)
@media only screen and ($desktopSize) {
  .white-container.cover-mode {
    .background-container:first-child {
      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }
  }
}

.background-switch, .project-details {
  cursor: pointer;
}

.background-switch {
  position: absolute;
  width: 50%;
  height: 100%;

  // Prevent blue highlightings of elements in Chrome
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);

  &.background-switch-left {
    left: 0%;
  }
  &.background-switch-right {
    left: 50%;
  }
}

header {
  .small-project-header-container {
    display: none;
    @media only screen and ($mobileSize) {
      display: flex;
      align-items: center;
    }
  }
  .project-header {
    text-align: right;
    font-family: $source-font;
    font-size: 18px;
    font-weight: 200;
    @media only screen and ($mobileSize) {
      font-size: 13px;
      &:not(.actu) {
        margin-right: 10px;
      }
      margin-left: 0px;
      line-height: 1.1;
      .title {
        font-weight: 400;
      }
      .lieu {
      }
    }
  }

  .top-title {
    min-width: 140px;
    a {
      color: black;
      display: block;
      line-height: 1.1;
      pointer-events: visible;
    }
    text-align: left;
    font-family: $source-font;
    font-size: 18px;
    font-weight: 200;

    @media only screen and ($mobileSize) {
      font-size: 18px;
    }

    .main-title {
      font-weight: 600;
    }
  }
  .logo-mobile-menu {
    @media only screen and ($desktopSize) {
      display: none;
    }
    pointer-events: visible;
    img {
      height: 35px;
    }
  }
}

footer {
  a {
    font-family: $source-font;
    font-size: 14px;
    font-weight: 200;
    color: black;
  }

  .project-menu {
    @media only screen and ($mobileSize) {
      display: none;
    }
  }
  .project-switch {
    @media only screen and ($mobileSize) {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      flex-wrap: wrap;
      align-content: center;
      a {
        font-size: 18px;
        margin-bottom: 2px;
      }
    }
    text-align: center;
    a {
      &:not(:last-child) {
        margin-right: 10px;
      }
      color: black;
      border-bottom: 1px solid transparent;
      &.active {
        border-bottom: 1px solid grey;
      }
      &.inactiveLink {
        pointer-events: none;
        cursor: default;
        color: grey;
      }
    }
  }
}

.menu-container {
  position: fixed;
  z-index: 2;
  .level {
  }
  @media only screen and ($desktopSize) {
    width: 100%;
    left: 0;
    height: 100px;
    bottom: -100px;

    &.show {
      bottom: 0;
    }
    &.always-show-desktop {
      bottom: 0;
    }
    padding-top: 5px;
    padding-bottom: 5px;
    @include transition(bottom, 0.5s);
    background-color: white;
    a {
      display: block;
      color: grey;
      font-family: $source-font;
      font-size: 14px;
      font-weight: 200;
      &.selected {
        color: black;
      }
    }
    /* Grid */
    &::after {
      content: "";
      display: table;
      clear: both;
    }

    .my-menu {
      height: 100%;
      padding-left: 50px;
      padding-right: 50px;
      width: 150vh;
      //margin: 0 auto;

      .level {
        width: 100px;
        height: 100%;
        float: left;
        &:not(:first-child) {
          margin-left: 50px;
        }
        &:not(:last-child) {
          margin-right: 10px;
        }
        position: relative;
        .menu-column {
          @include transition(opacity, 0.5s);
          position: absolute;
          opacity: 0;
          pointer-events: none;
          &.show {
            opacity: 1;
            pointer-events: visible;
          }
        }
      }
    }
  }
  @media only screen and ($mobileSize) {
    overflow-y: auto;
    height: 100%;
    width: 50%;
    bottom: 0;
    right: calc(-100vw / 2);
    &.show {
      right: 0;
    }
    @include transition(right, 0.5s);

    background-color: black;
    opacity: 0.85;
    .my-menu {
      padding-top: 18px;

      .logo-mobile-menu img {
        float: right;
        margin-right: 20px;
        margin-bottom: 30px;
        height: 35px;
      }
      .level {
        // Clearfix iPhone 6S
        clear: both;
        .menu-column {
          display: none;
          &.show {
            display: block;
          }
        }
        a {
          text-align: center;
          font-family: $source-font;
          display: block;
          color: grey;
          &.selected {
            color: white;
          }
        }
        &.level-1 > .menu-column {
          margin-bottom: 40px;
          & > a {
            margin-bottom: 50px;
            //letter-spacing: 10px;
            letter-spacing: 3px;
            text-transform: uppercase;
            font-weight: 600;
            font-size: 18px;
          }
        }
        &.level-2 > .menu-column {
          margin-bottom: 40px;
          & > a {
            margin-bottom: 40px;
            text-transform: uppercase;
            //font-weight: 600;
            font-size: 18px;
          }
        }
        &.level-3 > .menu-column {
          margin-bottom: 40px;
          & > a {
            margin-bottom: 20px;
            font-weight: 200;
            font-size: 18px;
          }
        }
      }
    }
  }

  .menu-elem {
    padding: 10px;
    cursor: pointer;
  }
}

.project-details-container {
  height: 100%;
  overflow: hidden;
  position: relative;

  // Prevent blue highlightings of elements in Chrome
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);

  @media only screen and ($desktopSize) {
    min-width: 25%;
    max-width: 340px;
  }
  @media only screen and ($mobileSize) {
    max-width: 100%;
  }
  float: right;
  pointer-events: none;
  &.show {
    pointer-events: visible;
    .project-details {
      left: 0%;
    }
  }
}
.project-details {
  height: 100%;
  text-align: left;
  background-color: black;
  opacity: 0.8;
  padding: 20px;
  color: white;
  // Scrolling bar
  overflow-y: auto;

  @include transition(left, 0.5s);

  @media only screen and ($mobileSize) {
    opacity: 1;
    background-color: white;
    color: black;
  }

  position: relative;
  left: 100%;

  ul.key-values {
    list-style: none;
    margin: 0;
  }

  //li {
    //&:after {
      //content: "";
      //clear: both;
    //}
  //}
  .key, .value {
    float: left;
    line-height: 1.5;
  }
  .key {
    color: #c9c9c9;
    width: 40%;
    font-family: $source-font;
    font-size: 14px;
    font-weight: 200;
  }
  .value {
    display: inline;
    width: 60%;
    font-family: $source-font;
    font-size: 14px;
    font-weight: 200;
  }
  .presentation {
    margin-top: 25px;
    margin-bottom: 15px;
    font-family: $source-font;
    font-size: 14px;
    font-weight: 200;
  }
}

.project-list {
  margin-left: -0.9375rem;
  margin-right: -0.9375rem;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-items: flex-start;
  align-content: flex-start;
  @media only screen and ($desktopSize) {
    padding-bottom: 30px;
  }
}

.project-container {
  padding-bottom: 30px;
  padding-left: 15px;
  padding-right: 15px;
  //display: none; // Mandatory for mixItUp
  @extend .block-size;

  .project-item {
    width: 100%;
    &:before  {
      content: "";
      float: left;
      padding-bottom: 66.667%;
    }
    &:after {
      content: "";
      display: table;
      clear: both;
    }
    background-size: cover;
    background-position: 50% 100%;
    position: relative;

    .project-details,
    .project-details-text {
      position: absolute;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
    }
    .project-details {
      background-color: white;
    }
    .project-details-text {
      color: black;
      text-align: center;

      display:flex;
      justify-content:center;
      align-items:center;
    }
  }

  &:hover {
    .project-details {
      @include transition(opacity, 0.5s);

      opacity: 0.75;
    }
    .project-details-text {
      @include transition(opacity, 0.5s);

      opacity: 1;
    }
  }

  font-family: $source-font;
  font-size: 16px;
  .title {
    margin-bottom: 0;
    font-weight: bold;
    @media only screen and ($mobileSize) {
      color: #636060;
      text-align: center;
      margin-top: 5px;
    }
  }
  .details {
    margin-bottom: 0;
    @media only screen and ($mobileSize) {
      color: #636060;
      text-align: center;
    }
  }
  @media only screen and ($mobileSize) {
    p {
      line-height: 1.2;
    }
  }

}

.news-list {
  @media only screen and ($desktopSize) {
    margin: 0 14%;
  }
}
.news-container {
  @extend .block-size-gutter;
}

$news-size: 300px;
@media only screen and (min-width: 1200px) {
  .news-list {
    width: 2 * $masonryGutter + 3 * $news-size;
    margin: 0 auto;
  }
  .news-container {
    width: $news-size;
  }
}

.news-list {
  a {
    color: black;
  }
  .news-container {
    margin-bottom: 10px;
    text-align: center;
    .news-image {
      width: 100%;
      background-size: 100%;
      background-repeat: no-repeat;
      //max-height: 250px;
      //&:hover {
        //opacity: 0.5;
      //}
    }
    p {
      margin-bottom: 5px;
      font-family: $source-font;
    }
    .news-date {
      margin-top: 10px;
      color: #747474;
      font-size: 12px;
      text-align: left;
    }
    .news-title {
      text-transform: uppercase;
      font-size: 14px;
      line-height: 1;
      text-align: left;
    }
    .news-text p {
      font-size: 12px;
      margin-bottom: 0;
      line-height: 1.4;
      text-align: left;
    }
    .news-links {
      text-align: left;
      font-size: 14px;
      a, a:hover, a:focus {
        color: black;
        font-size: 11px;
      }
    }
    .news-share {
      //text-align: left;
      @include transition(opacity, 0.5s);
      opacity: 0;
      &.show {
        opacity: 1;
      }
      img {
        width: 24px;
      }
    }
  }
}

#barba-wrapper {
  width: 100%;
  height: 100%;
}
.barba-container.my-menu {
  .white-container {
    padding: 0;
  }
  &, .white-container {
    // Fix click on container to hide menu in mobile
    height: 100vh;
  }
  .slideshow-container {
    pointer-events: none;
    height: 100%;
    top: 0;
    width: 100%;
    .background-container {
      img {
        height: 100%;
        width: 100%;
      }
    }
  }
  header, footer {
    //@media only screen and ($desktopSize) {
      //width: 150vh;
    //}
    width: 100%;
    margin: 0 auto;
    background-color: transparent;
  }
  header {
    .top-title a {
      color: white;
      padding-left: 50px;

      @media only screen and ($mobileSize) {
        padding-left: 20px;
      }
    }
    .logo-mobile-menu {
      padding-right: 20px;
    }
  }
  footer {
    .project-menu {
      .menu-elem {
        color: white;
        font-weight: bold;
        padding-left: 50px;
        padding-bottom: 20px;
        position: absolute;
        bottom: 0;
        font-size: 20px;
      }
    }
    .barmenu-launcher {
      pointer-events: visible;
      position: absolute;
      bottom: 0;
      left: 0;
      width: 40%;
      height: 50px;
    }
  }
}
.barba-container.agence-presentation {
  .grid-x {
    max-width: none;
    height: 100vh;
    //@media only screen and ($mobileSize) {
      //overflow-y: auto;
    //}
    .column-slideshow {
      padding-left: 0;
      @media only screen and ($desktopSize) {
        padding-right: 20px;
      }
      position: relative;
      @media only screen and ($mobileSize) {
        height: 50%;
      }
      @media only screen and ($desktopSize) {
        height: 100%;
      }
      .slideshow-container {
        @media only screen and ($desktopSize) {
          width: calc(100% - 20px);
        }
        @media only screen and ($mobileSize) {
          width: 100%;
        }
        height: 100%;
        top: 0;
      }
      // The image takes all the container size
      .background-container img {
        @media only screen and ($mobileSize) {
          width: 100%;
          height: 100%;
        }
      }
    }
    .column-text {
      height: 100%;
      overflow-x: hidden;
      overflow-y: auto;
      font-size: 14px;
      color: #757575;
      line-height: 1.3;
      font-family: $source-font;
      @media only screen and ($mobileSize) {
        height: auto;
        padding-top: 10px;
      }
    }
  }
}

.member-list {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-content: flex-start;
  .member-container {
    @extend .block-size;
    @media only screen and ($desktopSize) {
      min-width: 160px;
    }
    @media only screen and ($mobileSize) {
      padding-left: 10px;
      padding-right: 10px;
    }
    .member {
      margin: 0 auto;
      margin-bottom: 50px;
      .photo {
        max-width: 140px;
        height: 200px;
        background-size: cover;
        background-position: 50%;
        margin: 0 auto;
        margin-bottom: 10px;
      }
      color: #757575;
      font-family: $source-font;
      text-align: center;
      font-size: 12px;
      .name, .sub1, .sub2 {
        margin-bottom: 0;
      }
      .name {
        font-weight: bold;
      }
    }
  }
}

pre {
  white-space: pre-wrap;
}

header {
  top: 0px;
}
footer {
  bottom: 0px;
}
.barba-container.fullscreen-mobile {
  background-color: black;
  header {
    top: -70px;
  }
  footer {
    bottom: -70px;
  }
  .white-container {
    background-color: transparent;
    padding: 0;
  }
  .slideshow-container {
    width: 100%;
    height: 100vh;
    top: 0;
  }
}

// Fullscreen effect zoom
.barba-container.fullscreen-animate {
  transition: background-color 1s;
  header, footer {
    transition: all 1s;
  }
  .white-container {
    transition: padding 1s;
  }
  .slideshow-container {
    transition: all 1s;
  }
  .slick-track, .slick-slide {
    transition: width 1s, transform 1s;
  }
}

.project-list-details {
  a {
    font-family: $source-font;
    color: #575757;
    font-size: 18px;
    font-weight: 200;
  }

  .header {
    margin-top: 10px;
    margin-bottom: 15px;
    font-size: 22px;
    font-weight: 400;
    > * {
      cursor: pointer;
    }
  }
  .project-detail-container.animated {
    @include fadeIn();
  }

  .project-detail-container .cell {
    margin-bottom: 10px;
    padding-right: 30px;
  }
}

.project-list-menu {
  .search {
    width: 350px;
    background-image: url("/assets/img/search-picto.svg");
    background-repeat: no-repeat;
    background-position: 98%;
    background-size: 12px;
    border: 1px solid #cbcbcb;
    padding: 2px 5px 0px;
    border-radius: 2px;
    display: none;
    font-size: 14px;
    line-height: 1;
    &.show {
      display: inline-block;
    }
  }
  svg, .search {
    vertical-align: bottom;
    margin-left: 5px;
  }
  svg {
    height: 17px;
  }
  .details-picto, .grid-picto {
    outline: none;
  }
  .details-picto {
    svg .svg-details-1 {
      stroke: #cbcbcb;
      transition: stroke 0.5s;
    }
    &:hover, &.active {
      svg .svg-details-1 {
        stroke: #575757;
      }
    }
  }
  .grid-picto {
    svg rect {
      fill: #cbcbcb;
      transition: fill 0.5s;
    }
    &:hover, &.active {
      svg rect {
        fill: #575757;
      }
    }
  }
}
address {
  font-style: normal;
}

.switch-lang {
  pointer-events: visible;
  display: block;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 16px;
  font-weight: 400;
  text-align: center;
  &.only-desktop {
    padding-right: 50px;
  }
  a {
    padding-right: 5px;
    padding-left: 5px;
    display: inline-block !important;
  }
  &, a {
    color: white;
  }
}
.mentions-legales {
  padding-top: 10px;
  transition: opacity 0.5s;
  opacity: 0;
  &.show {
    opacity: 1;
  }
}
@media only screen and ($mobileSize) {
  .barba-container.agence-presentation footer,
  .barba-container.agence-membres footer {
    display: none;
  }
}

.slick-slide {
  position: relative;
}
.slick-list, .slick-track, .slick-slide > div {
  height: 100%;
}
.slick-slider {
  -ms-touch-action: auto;
  touch-action: auto;
}
